import {
	Button,
	Carousel,
	Col,
	Container,
	ListGroup,
	Nav,
	Navbar,
	Row,
} from "react-bootstrap"

import React from "react"
import YouTube from "react-youtube"
import slide1 from "../img/slide1.png"
import slide2 from "../img/slide2.png"
import slide3 from "../img/slide3.png"
import slide4 from "../img/slide4.png"

export const HomePage: React.FC = () => {
	return (
		<Container fluid className="px-0 text-light">
			<Row className="pt-5 g-0">
				<Col md={3} />
				<Col>
					<h1 className="text-light">
						<img src="/deckview-tv-64.png" className="me-2" alt="logo" />
						deckview.tv
					</h1>
					<h5>An alternative to YouTube &copy;</h5>
				</Col>
				<Col md={3} />
			</Row>
			<Row className="g-0">
				<Col md={3} />
				<Col>
					<Carousel interval={10000} className="bg-black my-3">
						<Carousel.Item>
							<img
								src={slide2}
								alt="Second"
								className="d-block w-100 slide-image"
							/>
							<Carousel.Caption className="bg-dark rounded">
								<h4>All subscriptions in one place</h4>
								<p>See the most recent uploads from all your subscriptions</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								src={slide1}
								alt="First"
								className="d-block w-100 slide-image"
							/>
							<Carousel.Caption className="bg-dark rounded">
								<h4>Never miss a video</h4>
								<p>Latest videos all at the top of the columns</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								src={slide3}
								alt="Third"
								className="d-block w-100 slide-image"
							/>
							<Carousel.Caption className="bg-dark rounded">
								<h4>Keep track of videos</h4>
								<p>
									Tracks the videos you've watched and resumes from your last
									viewing point
								</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								src={slide4}
								alt="Fourth"
								className="d-block w-100 slide-image"
							/>
							<Carousel.Caption className="bg-dark rounded">
								<h4>Customise to your liking</h4>
								<p>
									You determine what you see and how you see it. Choose when to
									start tracking, and when to mark as watched
								</p>
							</Carousel.Caption>
						</Carousel.Item>
					</Carousel>
				</Col>
				<Col md={3} />
			</Row>
			<Row className="g-0">
				<Col md={3} />
				<Col>
					<div className="d-grid gap-2">
						<Button href="/app" size="lg" variant="outline-light">
							Launch App
						</Button>
					</div>
				</Col>
				<Col md={3} />
			</Row>{" "}
			<Row className="mt-5">
				<Col md={3} />
				<Col>
					<h2>Features</h2>
					<ListGroup className="w-50">
						<ListGroup.Item>Keeps track of what you've watched</ListGroup.Item>
						<ListGroup.Item>
							Linked to your YouTube &copy; account
						</ListGroup.Item>
						<ListGroup.Item>
							Shows you only the content you want to see
						</ListGroup.Item>
					</ListGroup>
					<h2>Video Demo</h2>
					<YouTube
						videoId="tmGV8oKFlTM"
						opts={{
							width: "100%",
							playerVars: {
								modestbranding: 1,
								fs: 1,
								autoplay: 0,
							},
						}}
					/>
				</Col>
				<Col md={3} />
			</Row>
			<Row className="mt-5">
				<Col md={3} />
				<Col>
					<h2>Disclaimers</h2>
					<h4>What we do with user data</h4>
					<p>
						The only user data that is saved is your name, email, profile
						picture.
					</p>
					<p>
						Sensitives scopes about who are subscribed to on youtube are not
						saved and only used to create your columns. What columns you have
						created and the ids of the playlist(s) they contain are saved.
					</p>
					<h4>Copyright</h4>
					<p>
						If you have a copyright complaint please email me at{" "}
						<a href="mailto:ryan@deckview.tv">ryan@deckview.tv</a> with the page
						that contains the alleged content, identification of the work
						claimed to have been infringed including the name and reply email
						address of the copyright holder/representative, an assertion that
						the use of the material is not authorised and an assertion that you
						are the copyright holder/ representative.
					</p>
				</Col>
				<Col md={3} />
			</Row>
			<Navbar bg="dark" variant="dark" className="mt-5">
				<Nav>
					<Navbar.Text>
						<span className="ms-2 text-secondary">&copy; deckview.tv 2022</span>
					</Navbar.Text>
					<Nav.Link href="/privacy" className="ms-3">
						Privacy Policy
					</Nav.Link>
					<Nav.Link href="/tos">Terms of Service</Nav.Link>
				</Nav>
			</Navbar>
		</Container>
	)
}
