import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { PrivacyPolicy } from "./frontend/pages/privacy-policy"
import { TermsOfService } from "./frontend/pages/terms-of-service"
import App from "./frontend/routes/app"
import { Bullshit } from "./frontend/routes/bullshit"
import { HomePage } from "./frontend/routes/home-page"

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/app" element={<App />} />
				<Route
					path="/privacy"
					element={
						<Bullshit>
							<PrivacyPolicy />
						</Bullshit>
					}
				/>
				<Route
					path="/tos"
					element={
						<Bullshit>
							<TermsOfService />
						</Bullshit>
					}
				/>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
)
