import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import React from "react"
import { ListGroupItem } from "react-bootstrap"

interface Props {
	id: string
	data: GoogleApiYouTubeChannelResource
}

export const SortableItem: React.FC<Props> = ({ data: { snippet }, id }) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: id })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	}

	return (
		<ListGroupItem
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			className="align-middle bg-grey-30 text-white"
		>
			<img
				className="playlist-icon me-2"
				src={snippet.thumbnails.default.url}
				alt="logo"
			/>
			{snippet.title}
		</ListGroupItem>
	)
}
