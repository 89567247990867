import React, { useEffect, useState } from "react"
import { Container, Modal } from "react-bootstrap"
import { SettingsContext, UserContext } from "../lib/context"
import {
	getAccountData,
	getColumnData,
	getSettings,
	getUserPermissions,
	loginGoogle,
	refreshGoogle,
	setColumnData,
	updateSettings,
} from "../lib/fetcher"
import { ChannelMap, GoogleCode } from "../lib/interfaces"
import { getSubscriptions } from "../lib/wapis"
import { ColumnManager } from "./column-manager"
import { MainNav } from "./main-nav"
import { SettingsManager } from "./settings-manager"
import SubBox from "./sub-box"

import "../css/Player.scss"
import { components } from "../generated/deckview.tv"
import { CustomColumnManager } from "./custom-column-manager"

const MainBody: React.FC = () => {
	// region: User data
	const [googleCode, setGoogleCode] = useState<GoogleCode | null>(null)
	const [googleRefreshToken, setRefreshToken] = useState<GoogleCode | null>(
		null
	)
	const [doRefresh, setDoRefresh] = useState(false)
	const [googleData, setGoogleData] = useState<
		components["schemas"]["GoogleToken"] | null
	>(null)
	const [accountData, setAccountData] = useState<
		components["schemas"]["User"] | null
	>(null)
	const [permissions, setPermissions] = useState<
		components["schemas"]["UserPermissions"] | null
	>(null)
	const [loggedIn, setLoggedIn] = useState<boolean>(false)
	const [columnsFetched, setColumnsFetched] = useState<boolean>(false)

	const handleLogout = () => {
		localStorage.removeItem("googleData")
		setRefreshToken(null)
		setGoogleData(null)
		setAccountData(null)
		setSubs({})
		setPage("")
		setLoggedIn(false)
		setColumnsFetched(false)
		setColumns([])
	}

	// Check local storage for user data on mount
	useEffect(() => {
		const localUser = JSON.parse(localStorage.getItem("googleData") || "{}")
		setRefreshToken({ code: localUser.refresh_token })
	}, [])

	useEffect(() => {
		if (googleCode?.code) {
			loginGoogle({}, { headers: { Authorization: googleCode.code } }).then(
				(result) => {
					setGoogleData(result.data)
					localStorage.setItem("googleData", JSON.stringify(result.data))
				}
			)
		}
	}, [googleCode])

	useEffect(() => {
		if (googleRefreshToken?.code || (doRefresh && googleRefreshToken?.code)) {
			refreshGoogle(
				{},
				{ headers: { Authorization: googleRefreshToken.code } }
			).then((result) => {
				result.data.refresh_token = googleRefreshToken.code
				setGoogleData(result.data)
				localStorage.setItem("googleData", JSON.stringify(result.data))
				setDoRefresh(false)
			})
		}
	}, [googleRefreshToken, doRefresh])

	//Refresh the access_token on an interval
	useEffect(() => {
		const f = () => {
			setDoRefresh(true)
		}
		const i = window.setInterval(f, 1000 * 3400)
		return () => {
			window.clearInterval(i)
		}
	}, [googleData?.refresh_token])

	// Check if the user can log in
	useEffect(() => {
		if (!loggedIn && googleData?.access_token) {
			getAccountData(
				{},
				{
					headers: { Authorization: googleData?.access_token },
				}
			)
				.then((result) => {
					setAccountData(result.data)
				})
				.catch(handleLogout)
		}
	}, [googleData?.access_token, googleData?.refresh_token, loggedIn])
	// Check if the user can log in
	useEffect(() => {
		if (loggedIn && googleData?.access_token) {
			getUserPermissions(
				{},
				{
					headers: { Authorization: googleData?.access_token },
				}
			)
				.then((result) => {
					setPermissions(result.data)
				})
				.catch(() => {})
		}
	}, [googleData?.access_token, googleData?.refresh_token, loggedIn])

	useEffect(() => {
		if (!columnsFetched && googleData?.access_token) {
			setColumnsLoading(true)
			getColumnData(
				{},
				{
					headers: { Authorization: googleData?.access_token },
				}
			).then((result) => {
				setColumns(result.data.columns || [])
				setLoggedIn(true)
				setColumnsFetched(true)
				setColumnsLoading(false)
			})
		}
	}, [columnsFetched, googleData?.access_token])

	useEffect(() => {
		if (googleData?.access_token) {
			getSettings(
				{},
				{ headers: { Authorization: googleData?.access_token } }
			).then((result) => {
				parseSettings(result.data)
			})
		}
	}, [googleData?.access_token])

	// endregion

	//region: Videos
	const [subs, setSubs] = useState<ChannelMap>({})
	const [columns, setColumns] = useState<components["schemas"]["UserColumn"][]>(
		[]
	)
	const [columnsLoading, setColumnsLoading] = useState(false)
	// Get Subs
	const [page, setPage] = useState<string>("")

	useEffect(() => {
		if (loggedIn && googleData?.access_token) {
			getSubscriptions(googleData.access_token, page).then((channelData) => {
				if (channelData.items?.length > 0) {
					setSubs((state) => {
						const slice = Object.assign({}, state)
						for (const item of channelData.items) {
							slice[item.id] = item
						}
						return slice
					})

					if (channelData.nextPageToken) {
						setPage(channelData.nextPageToken)
					}
				}
			})
		}
	}, [loggedIn, googleData?.access_token, page])

	//endregion

	// region: Settings

	const [hideWatched, setHideWatched] = useState<boolean>(false)
	const [startPercent, setStartPercent] = useState<number>(5)
	const [finishPercent, setFinishPercent] = useState<number>(95)
	const [fullscreen, setFullscreen] = useState<boolean>(false)

	const [showColumnManager, setShowColumnManager] = useState<boolean>(false)
	const handleColumnManagerClose = (): void => setShowColumnManager(false)
	const handleColumnManagerShow = (): void => setShowColumnManager(true)

	const [showSettingsManager, setShowSettingsManager] = useState<boolean>(false)
	const handleSettingsManagerClose = (): void => setShowSettingsManager(false)
	const handleSettingsManagerShow = (): void => setShowSettingsManager(true)

	const [showCustomColumnManager, setShowCustomColumnManager] = useState(false)
	const handleCustomColumnManagerClose = (): void =>
		setShowCustomColumnManager(false)
	const handleCustomColumnManagerShow = (): void =>
		setShowCustomColumnManager(true)

	const handleToggleShowWatched = () => {
		const slice = Object.assign({}, settingsContext)
		slice.hide_watched = !slice.hide_watched
		updateSettings(slice, {
			headers: { Authorization: googleData?.access_token || "" },
		}).then((result) => {
			parseSettings(result.data)
		})
		setHideWatched(!hideWatched)
	}

	const handleSaveColumns = (ids: string[]) => {
		const toKeep = Object.fromEntries(
			columns
				.filter((item) => ids.includes(item.channel_id))
				.map((item) => [item.channel_id, item])
		)
		const newColumnIds = ids.filter((id) => !Object.hasOwn(toKeep, id))
		const newColumns = Object.fromEntries(
			newColumnIds.map((id) => {
				const sub = subs[id]
				return [
					id,
					{
						title: sub.snippet.title,
						playlist_ids: [sub.contentDetails.relatedPlaylists.uploads],
						logo: sub.snippet.thumbnails.default.url,
						channel_id: id,
					},
				]
			})
		)

		const columnsToSet = ids.map((id) => {
			if (toKeep[id] !== undefined) return toKeep[id]
			if (newColumns[id] !== undefined) return newColumns[id]
			throw new Error()
		})

		setColumns(columnsToSet)
		setColumnData(
			{ columns: columnsToSet, id: accountData?.id || "" },
			{ headers: { Authorization: googleData?.access_token || "" } }
		)
		setShowColumnManager(false)
	}

	const handleSaveSettings = (data: components["schemas"]["UserSettings"]) => {
		updateSettings(data, {
			headers: { Authorization: googleData?.access_token || "" },
		}).then((result) => {
			parseSettings(result.data)
		})
		setShowSettingsManager(false)
	}

	const parseSettings = (data: components["schemas"]["UserSettings"]) => {
		setHideWatched(data.hide_watched)
		setStartPercent(data.start_percent)
		setFinishPercent(data.finish_percent)
		setFullscreen(data.fullscreen)
	}

	// endregion

	// region:Contexts

	const userContext = {
		google_data: googleData,
		account_data: accountData,
	}

	const settingsContext: components["schemas"]["UserSettings"] = {
		id: accountData?.id || "",
		hide_watched: hideWatched,
		fullscreen: fullscreen,
		start_percent: startPercent,
		finish_percent: finishPercent,
	}

	// endregion

	// @ts-ignore
	return (
		<Container fluid className="fill-height p-0">
			<MainNav
				hideWatched={hideWatched}
				showColumnManager={handleColumnManagerShow}
				showSettingsManager={handleSettingsManagerShow}
				showCustomColumnManager={handleCustomColumnManagerShow}
				toggleHideWatched={handleToggleShowWatched}
				login={setGoogleCode}
				logout={handleLogout}
				user={accountData}
				permissions={permissions}
			/>
			<Modal
				show={showColumnManager}
				size={"lg"}
				centered
				onHide={handleColumnManagerClose}
				contentClassName="border-0"
			>
				<ColumnManager
					columns={columns}
					subs={subs}
					handleClose={handleColumnManagerClose}
					handleSave={handleSaveColumns}
				/>
			</Modal>
			<Modal
				show={showSettingsManager}
				centered
				onHide={handleSettingsManagerClose}
				contentClassName="border-0"
			>
				{accountData && (
					<SettingsManager
						userData={accountData}
						handleClose={handleSettingsManagerClose}
						handleSave={handleSaveSettings}
						settings={settingsContext}
					/>
				)}
			</Modal>
			{permissions?.custom_columns && (
				<Modal
					show={showCustomColumnManager}
					centered
					onHide={handleCustomColumnManagerClose}
					contentClassName="border-0"
				>
					<CustomColumnManager
						handleClose={handleCustomColumnManagerClose}
						handleSave={() => {}}
					/>
				</Modal>
			)}
			<UserContext.Provider value={userContext}>
				<SettingsContext.Provider value={settingsContext}>
					<SubBox
						ready={loggedIn}
						columns={columns}
						columnsLoading={columnsLoading}
						subs={subs}
						hideWatched={hideWatched}
					/>
				</SettingsContext.Provider>
			</UserContext.Provider>
		</Container>
	)
}

export default MainBody
