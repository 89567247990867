import React, { ReactNode } from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

interface Props {
	children: ReactNode
}

export const Bullshit: React.FC<Props> = (props) => {
	return (
		<Container className="text-light">
			<Row>
				<Col className="text-center">
					<Link to="/" className="text-decoration-underline text-white">
						<Image src="/deckview-tv-256.png" />
						<h4>Go Back</h4>
					</Link>
				</Col>
			</Row>
			<Row>
				<Col>{props.children}</Col>
			</Row>
		</Container>
	)
}
