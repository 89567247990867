import React, { useCallback, useEffect, useMemo, useState } from "react"
import PlayerStates from "youtube-player/dist/constants/PlayerStates"
import { YouTubePlayer } from "youtube-player/dist/types"
import { components } from "../../generated/deckview.tv"
import { patchWatchData } from "../../lib/fetcher"
import { WatchMap } from "../../lib/interfaces"
import { VideoPlayer } from "../video-player"
import { Video } from "./video"

interface Props {
	playlist_ids: string[]
	startPercent: number
	finishPercent: number
	fullscreen: boolean
	googleData: components["schemas"]["GoogleToken"]
	loaded: boolean
	videoData: GoogleApiYouTubeVideoResource[]
	watchData: WatchMap
	toggleWatched: (id: string) => void
	initiateRecheck: React.MutableRefObject<() => void>
	setIsPlaying: (state: boolean) => void
}

export const Videos = React.memo<Props>(
	({
		finishPercent,
		fullscreen,
		googleData,
		initiateRecheck,
		setIsPlaying,
		startPercent,
		toggleWatched,
		videoData,
		watchData,
	}) => {
		const [localWatchData, setLocalWatchData] = useState<WatchMap>(watchData)
		const [showPlayer, setShowPlayer] = useState(false)
		const [currentVideo, setCurrentVideo] = useState<{
			video?: GoogleApiYouTubeVideoResource
			watch?: components["schemas"]["WatchData"]
		}>({})
		const [playerElement, setPlayerElement] = useState<YouTubePlayer | null>(
			null
		)

		useEffect(() => {
			setLocalWatchData(watchData)
		}, [watchData])

		const handleShowPlayer = (id: string) => {
			const videoMap = Object.fromEntries(videoData.map((v) => [v.id, v]))
			setCurrentVideo({
				watch: localWatchData[id],
				video: videoMap[id],
			})
			setShowPlayer(true)
			setIsPlaying(true)
		}

		const handleHidePlayer = () => {
			setShowPlayer(false)
			setIsPlaying(false)
		}
		const updateWatchData = useCallback(
			(
				newWatchEvent: {
					id: string
					video_id: string
					progress_percent: number
					watched: boolean
					progress: number
					finished: boolean
				},
				accessToken: string
			) => {
				patchWatchData([newWatchEvent], {
					headers: { Authorization: accessToken },
				}).then((result) => {
					const slice = Object.assign({}, localWatchData)
					slice[result.data[newWatchEvent.video_id].video_id] =
						result.data[newWatchEvent.video_id]
					setLocalWatchData(slice)
				})
			},
			[localWatchData]
		)

		const handlePlaybackEvent = useCallback(
			(e: { target: YouTubePlayer }) => {
				// @ts-ignore
				let progress = Math.round(e.target.getCurrentTime())
				// @ts-ignore
				let progressPercent = (progress / e.target.getDuration()) * 100
				setPlayerElement(e.target)

				if (progressPercent < (startPercent || 3)) {
					progress = 0
					progressPercent = 0
				}

				const watched: boolean = progressPercent > (finishPercent || 95)

				if (currentVideo.video?.id) {
					const newWatchEvent = {
						id: googleData.id_token || "",
						video_id: currentVideo.video.id,
						progress_percent: Math.round(progressPercent),
						progress: progress,
						watched: watched,
						finished: watched,
					}
					updateWatchData(newWatchEvent, googleData.access_token || "")
				}
				initiateRecheck.current()
			},
			[
				currentVideo.video?.id,
				finishPercent,
				googleData.access_token,
				initiateRecheck,
				startPercent,
				updateWatchData,
			]
		)

		useEffect(() => {
			if (
				playerElement &&
				// @ts-ignore
				playerElement.getPlayerState() === PlayerStates.PLAYING
			) {
				const i = window.setInterval(
					() => handlePlaybackEvent({ target: playerElement }),
					1000 * 60
				)

				return () => window.clearInterval(i)
			}
		}, [handlePlaybackEvent, playerElement, showPlayer])

		const sortedVideos = useMemo(
			() =>
				videoData
					.sort((a, b) =>
						a.snippet.publishedAt < b.snippet.publishedAt ? -1 : 1
					)
					.reverse(),
			[videoData]
		)

		return (
			<>
				{currentVideo.video && currentVideo.watch && (
					<VideoPlayer
						show={showPlayer}
						handleHide={handleHidePlayer}
						logo={"/deckview-tv-64.png"}
						videoData={currentVideo.video || {}}
						watchData={currentVideo.watch || {}}
						onStateChange={(e) => handlePlaybackEvent(e)}
						modalFullscreen={fullscreen}
					/>
				)}
				<>
					{sortedVideos.map((item) => {
						return (
							<Video
								key={item.id}
								videoData={item}
								watchData={localWatchData[item.id]}
								showVideo={handleShowPlayer}
								googleData={googleData}
								toggleWatched={toggleWatched}
							/>
						)
					})}
				</>
			</>
		)
	}
)
