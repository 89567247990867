import { GoogleOAuthProvider } from "@react-oauth/google"
import MainBody from "../components/main-body"
import "../css/App.scss"

const { REACT_APP__GOOGLE_CLIENT_ID } = process.env

function App() {
	return (
		<GoogleOAuthProvider clientId={REACT_APP__GOOGLE_CLIENT_ID || ""}>
			<MainBody />
		</GoogleOAuthProvider>
	)
}

export default App
