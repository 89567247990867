import { Fetcher, Middleware } from "openapi-typescript-fetch"
import { paths } from "../generated/deckview.tv"

const { REACT_APP__BACKEND_HOST } = process.env

const fetcher = Fetcher.for<paths>()

const AuthMapper: Middleware = async (url, init, next) => {
	const xAuth = init.headers.get("X-CheckAuth")
	const currentAuth = init.headers.get("Authorization")

	if (xAuth) {
		init.headers.set("Authorization", `Bearer check:${xAuth}`)
	} else {
		init.headers.set("Authorization", `Bearer ${currentAuth}`)
	}

	return await next(url, init)
}

const CORSMiddleware: Middleware = async (url, init, next) => {
	init.headers.set(
		"Access-Control-Allow-Origin",
		REACT_APP__BACKEND_HOST || "*"
	)

	return await next(url, init)
}

fetcher.configure({
	baseUrl: REACT_APP__BACKEND_HOST,
	use: [AuthMapper, CORSMiddleware],
})

export const getAccountData = fetcher
	.path("/api/v1/accounts/account_data")
	.method("post")
	.create()

export const getUserPermissions = fetcher
	.path("/api/v1/accounts/user_permissions")
	.method("get")
	.create()

export const getColumnData = fetcher
	.path("/api/v1/activity/user_columns")
	.method("get")
	.create()

export const setColumnData = fetcher
	.path("/api/v1/activity/user_columns")
	.method("post")
	.create()

export const getWatchData = fetcher
	.path("/api/v1/activity/watch_data")
	.method("post")
	.create()

export const patchWatchData = fetcher
	.path("/api/v1/activity/watch_data")
	.method("patch")
	.create()

export const getSettings = fetcher
	.path("/api/v1/accounts/user_settings")
	.method("get")
	.create()

export const updateSettings = fetcher
	.path("/api/v1/accounts/user_settings")
	.method("post")
	.create()

export const loginGoogle = fetcher
	.path("/api/v1/auth/login_google")
	.method("post")
	.create()

export const refreshGoogle = fetcher
	.path("/api/v1/auth/refresh_google")
	.method("post")
	.create()
