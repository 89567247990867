import React from "react"
import { Button, Modal } from "react-bootstrap"

interface Props {
	handleClose: () => void
	handleSave: (data: object) => void
}

export const CustomColumnManager: React.FC<Props> = ({
	handleClose,
	handleSave,
}) => (
	<>
		<Modal.Header className="bg-grey-20 text-white border-dark" closeButton>
			Custom Column Manager
		</Modal.Header>
		<Modal.Body>
			<div>This is a thing</div>
		</Modal.Body>
		<Modal.Footer className="bg-grey-20 p-1 border-dark">
			<Button variant="outline-dark text-light" onClick={handleClose}>
				Close
			</Button>
			<Button variant="secondary" onClick={() => handleSave({})}>
				Save changes
			</Button>
		</Modal.Footer>
	</>
)
