import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core"
import { DragStartEvent } from "@dnd-kit/core/dist/types"
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import React, { ReactNode, useState } from "react"
import { Button, Col, Container, ListGroup, Modal, Row } from "react-bootstrap"
import { components } from "../generated/deckview.tv"
import { ChannelMap } from "../lib/interfaces"
import useWindowDimensions from "../lib/util"
import { Item } from "./item"
import { SortableItem } from "./sortable-item"

interface DroppableEmptyProps {
	id: string
	children: ReactNode
}

const DroppableEmpty: React.FC<DroppableEmptyProps> = ({ children, id }) => {
	const { setNodeRef } = useDroppable({ id: id })

	return (
		<ListGroup.Item
			className="text-center bg-grey-15 text-white"
			style={{ height: 150 }}
			ref={setNodeRef}
		>
			{children}
		</ListGroup.Item>
	)
}

const keysSortedByName = (data: ChannelMap, exclude: string[]) => {
	const keyData = Object.entries(data)
	keyData.sort((a, b) => {
		return a[1].snippet.title.toLocaleLowerCase() >
			b[1].snippet.title.toLocaleLowerCase()
			? 1
			: -1
	})

	const ids = keyData.map((i) => i[0])
	return ids.filter((k) => !exclude.includes(k))
}

interface Props {
	columns: components["schemas"]["UserColumn"][]
	subs: ChannelMap
	handleClose: () => void
	handleSave: (cols: string[]) => void
}

export const ColumnManager: React.FC<Props> = ({
	columns,
	handleClose,
	handleSave,
	subs,
}) => {
	const { height } = useWindowDimensions()

	const [activeId, setActiveId] = useState<string | null>(null)
	const [currentColumns, setCurrentColumns] = useState<string[]>(
		columns.map((col) => {
			return col.channel_id
		})
	)
	const [availableColumns, setAvailableColumns] = useState(
		keysSortedByName(subs, currentColumns)
	)
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)
	const handleDragStart = (event: DragStartEvent) => {
		setActiveId(event.active.id.toString())
	}

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event

		const sourceContainer = active.data.current?.sortable.containerId
		let targetContainer: string

		if (!over) return

		if (over.id === "empty-a") {
			targetContainer = "COLUMNS"
		} else if (over.id === "empty-b") {
			targetContainer = "SUBS"
		} else {
			targetContainer = over.data.current?.sortable.containerId
		}

		if (sourceContainer === "SUBS" && targetContainer === "COLUMNS") {
			setCurrentColumns((state) => {
				const overIndex = state.indexOf(over.id.toString())

				const start = state.slice(0, overIndex)
				const end = state.slice(overIndex)

				const slice = []

				slice.push(...start)
				slice.push(active.id.toString())
				slice.push(...end)

				return slice
			})
			setAvailableColumns((state) => {
				const slice = state.slice()
				slice.splice(slice.indexOf(active.id.toString()), 1)
				return slice
			})
		} else if (sourceContainer === "COLUMNS" && targetContainer === "COLUMNS") {
			if (active.id !== over.id) {
				setCurrentColumns((state) => {
					const oldIndex = state.indexOf(active.id.toString())
					const newIndex = state.indexOf(over.id.toString())

					return arrayMove(state, oldIndex, newIndex)
				})
			}
		} else if (sourceContainer === "COLUMNS" && targetContainer === "SUBS") {
			let newColumnState: string[]
			setCurrentColumns((state) => {
				const slice = state.slice()
				slice.splice(slice.indexOf(active.id.toString()), 1)
				newColumnState = slice
				return slice
			})
			setAvailableColumns(() => {
				return keysSortedByName(subs, newColumnState)
			})
		}

		setActiveId(null)
	}

	return (
		<>
			{!!Object.values(subs).length && (
				<>
					<Modal.Header
						className="bg-grey-20 text-white border-dark"
						closeButton
					>
						Manage Columns
					</Modal.Header>
					<Modal.Body className="bg-grey-10 p-0">
						<Container fluid>
							<Row className="text-center text-light">
								<Col xs={6}>Available</Col>
								<Col xs={6}>Current</Col>
							</Row>
							<Row>
								<DndContext
									sensors={sensors}
									collisionDetection={closestCenter}
									onDragStart={handleDragStart}
									onDragEnd={handleDragEnd}
								>
									<SortableContext
										items={availableColumns}
										strategy={verticalListSortingStrategy}
										id="SUBS"
									>
										<Col
											xs={6}
											className="p-0"
											style={{ overflowY: "auto", maxHeight: height * 0.7 }}
										>
											<ListGroup variant="flush">
												{availableColumns.length ? (
													availableColumns.map((id) => (
														<SortableItem key={id} id={id} data={subs[id]} />
													))
												) : (
													<DroppableEmpty id="empty-b">
														No more subscriptions found.
													</DroppableEmpty>
												)}
											</ListGroup>
										</Col>
									</SortableContext>
									<SortableContext
										items={currentColumns}
										strategy={verticalListSortingStrategy}
										id="COLUMNS"
									>
										<Col
											xs={6}
											className="p-0"
											style={{ overflowY: "auto", maxHeight: height * 0.7 }}
										>
											{currentColumns.length ? (
												<ListGroup variant="flush">
													{currentColumns.map((id) => (
														<SortableItem key={id} id={id} data={subs[id]} />
													))}
												</ListGroup>
											) : (
												<DroppableEmpty id="empty-a">
													Add columns by dropping them here!
												</DroppableEmpty>
											)}
										</Col>
									</SortableContext>
									<DragOverlay>
										{activeId ? (
											<Item id={activeId} data={subs[activeId]} />
										) : null}
									</DragOverlay>
								</DndContext>
							</Row>
						</Container>
					</Modal.Body>
					<Modal.Footer className="bg-grey-20 p-1 border-dark">
						<Button variant="outline-dark text-light" onClick={handleClose}>
							Close
						</Button>
						<Button
							variant="secondary"
							onClick={() => handleSave(currentColumns)}
						>
							Save changes
						</Button>
					</Modal.Footer>
				</>
			)}
		</>
	)
}
