import { faEyeSlash, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react"
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { components } from "../generated/deckview.tv"
import { getWatchData, patchWatchData } from "../lib/fetcher"
import { WatchMap } from "../lib/interfaces"
import { sortVideos } from "../lib/util"
import { getVideoStats, getVideos } from "../lib/wapis"
import { Videos } from "./subs/videos"

enum ActionType {
	push = "push",
	cleanup = "cleanup",
	sort = "sort",
	remove = "remove",
}

interface ActionInterface<T> {
	type: ActionType
	items?: T
}

function videoReducer(
	state: GoogleApiYouTubeVideoResource[],
	action: ActionInterface<GoogleApiYouTubeVideoResource[]>
) {
	switch (action.type) {
		case ActionType.push:
			if (action?.items) {
				const slice = state.slice()
				slice.push(...action.items)
				return slice
			} else {
				return state
			}

		case ActionType.cleanup:
			return []

		case ActionType.sort:
			if (action?.items) {
				const slice = state.slice()
				slice.sort(sortVideos)
				return slice
			} else {
				return state
			}

		default:
			throw new Error()
	}
}

function watchReducer(state: WatchMap, action: ActionInterface<WatchMap>) {
	switch (action.type) {
		case ActionType.push:
			if (action?.items) {
				const slice = Object.assign({}, state)
				for (const [k, v] of Object.entries(action.items)) {
					slice[k] = v
				}
				return slice
			} else {
				return state
			}

		case ActionType.cleanup:
			return {}

		default:
			throw new Error()
	}
}

interface Props {
	column: components["schemas"]["UserColumn"]
	googleData: components["schemas"]["GoogleToken"]
	hideWatched: boolean
	startPercent: number
	finishPercent: number
	fullscreen: boolean
}

export const SubBoxColumn = React.memo<Props>(
	({
		column: { channel_id, logo, playlist_ids, title },
		finishPercent,
		fullscreen,
		googleData,
		hideWatched,
		startPercent,
	}) => {
		const [videoData, videoDispatch] = useReducer(videoReducer, [])
		const [watchData, watchDispatch] = useReducer(watchReducer, {})
		const [loaded, setLoaded] = useState(false)
		const [toLoad, setToLoad] = useState(playlist_ids.length)
		const [toWatch, setToWatch] = useState<string[]>([])
		const [forceRecheck, setForceRecheck] = useState(false)
		const initiateRecheck = useRef(() => {
			setForceRecheck((state) => !state)
		})
		const [busyWatchAll, setBusyWatchAll] = useState(false)
		const [isPlaying, setIsPlaying] = useState(false)

		useEffect(() => {
			if (loaded) return
			if (!googleData.access_token) return

			const { access_token } = googleData

			playlist_ids.forEach((playlistId) => {
				getVideos(access_token, playlistId)
					.then((newVideoData) => {
						if (newVideoData?.items) {
							const videoIds: string[] = newVideoData.items.map(
								(v: GoogleApiYouTubePlaylistItemResource) => {
									return v.snippet.resourceId.videoId
								}
							)
							setToWatch((state) => {
								const slice = state.slice()
								slice.push(...videoIds)
								return slice
							})
							getVideoStats(access_token, videoIds).then((statsData) => {
								videoDispatch({
									type: ActionType.push,
									items: statsData.items,
								})
								setToLoad((state) => state - 1)
							})
						}
					})
					.catch((err) => {
						console.log(err)
					})
			})
		}, [playlist_ids, loaded, googleData?.access_token])

		//Re-fetch the watch data on an interval
		// useEffect(() => {
		//   const f = async () =>
		//     getWatchData(
		//       { ids: toWatch },
		//       {
		//         headers: {
		//           Authorization: props.googleData?.access_token || "",
		//         },
		//       }
		//     ).then((result) => {
		//       const watchMap = Object.fromEntries(
		//         result.data.videos.map((item) => [item.video_id, item])
		//       );
		//       watchDispatch({ type: ActionType.push, items: watchMap });
		//     });
		//   const i = window.setInterval(f, 1000 * 180);
		//   return () => {
		//     window.clearInterval(i);
		//   };
		// }, [props.googleData?.access_token, toWatch]);

		// Fetch all the watch data
		const watched = useRef<WatchMap>(watchData)
		useEffect(() => {
			if (googleData?.access_token) {
				const toUpdate: string[] = []

				for (const v of toWatch) {
					if (!Object.keys(watched.current).includes(v)) {
						toUpdate.push(v)
					}
				}

				if (toUpdate.length > 0) {
					getWatchData(toUpdate, {
						headers: { Authorization: googleData.access_token },
					}).then((result) => {
						const newData = Object.fromEntries(
							result.data.map((item) => [item.video_id, item])
						)
						watchDispatch({ type: ActionType.push, items: newData })
					})
				}
			}
		}, [googleData.access_token, toWatch, forceRecheck])

		// Check if all watch data has been fetched
		useEffect(() => {
			if (toLoad === 0) {
				const watchIds = Object.values(watchData).map((item) => item.video_id)
				let shouldSetLoaded = true
				for (const id of toWatch) {
					if (!watchIds.includes(id)) {
						shouldSetLoaded = false
						break
					}
				}

				if (shouldSetLoaded) setLoaded(true)
			}
		}, [watchData, toWatch, toLoad])

		const [visible, setVisible] = useState<boolean>(false)

		useEffect(() => {
			const videos = Object.keys(watchData).length
			const watched = Object.values(watchData).filter(
				(item) => item.watched
			).length
			setVisible(videos !== watched)
		}, [watchData])

		const handleWatchAll = () => {
			setBusyWatchAll(true)
			const slice = Object.assign({}, watchData)
			const updateEvents: components["schemas"]["WatchData"][] = []
			Object.keys(watchData).forEach((k) => {
				slice[k].watched = true
				const newWatchEvent: components["schemas"]["WatchData"] = {
					id: googleData.id_token || "",
					video_id: k,
					watched: true,
					finished: slice[k].finished,
					progress_percent: slice[k].progress_percent,
					progress: slice[k].progress,
				}
				updateEvents.push(newWatchEvent)
			})
			patchWatchData(updateEvents, {
				headers: { Authorization: googleData?.access_token || "" },
			})
				.then((result) => {
					watchDispatch({ type: ActionType.push, items: result.data })
					setBusyWatchAll(false)
				})
				.catch((err) => {
					console.log(err)
					setBusyWatchAll(false)
				})
		}

		const handleToggleWatched = (id: string) => {
			const slice = watchData[id]
			slice.watched = !slice.watched
			const data: WatchMap = {}
			data[id] = slice
			watchDispatch({ type: ActionType.push, items: data })
			patchWatchData([slice], {
				headers: { Authorization: googleData?.access_token || "" },
			})
		}

		const toggleWatchButton = useMemo(() => {
			return (
				<OverlayTrigger
					placement="top"
					delay={500}
					overlay={<Tooltip>Set all watched</Tooltip>}
				>
					<div className="px-1">
						<FontAwesomeIcon
							transform="grow-3"
							className="text-white"
							// @ts-ignore
							icon={busyWatchAll ? faSpinner : faEyeSlash}
							pulse={busyWatchAll}
						/>
					</div>
				</OverlayTrigger>
			)
		}, [busyWatchAll])
		return (
			<>
				{visible || !hideWatched || isPlaying ? (
					<div className={"playlist-column"}>
						<Card className="fill-min-height border-0">
							<Card.Header className="playlist-title bg-grey-10 text-light p-2">
								<div className="w-100 d-flex justify-content-between align-content-center align-middle flex-row">
									<a
										target="_blank"
										href={`https://www.youtube.com/channel/${channel_id}/videos`}
										rel="noreferrer"
									>
										<img
											src={logo}
											alt={title}
											loading="lazy"
											className="playlist-icon me-2"
											referrerPolicy="no-referrer"
										/>
										{title}
									</a>
									<Button
										onClick={handleWatchAll}
										disabled={busyWatchAll}
										variant="secondary"
									>
										{toggleWatchButton}
									</Button>
								</div>
							</Card.Header>
							<Card.Body className="playlist-body bg-grey-20 text-light p-1">
								{loaded && (
									<Videos
										playlist_ids={playlist_ids}
										googleData={googleData}
										fullscreen={fullscreen}
										finishPercent={finishPercent}
										startPercent={startPercent}
										videoData={videoData}
										loaded={loaded}
										watchData={watchData}
										toggleWatched={handleToggleWatched}
										initiateRecheck={initiateRecheck}
										setIsPlaying={setIsPlaying}
									/>
								)}
								{!loaded && (
									<div className="w-100 text-center px-5 py-4">
										<FontAwesomeIcon
											pulse
											transform="grow-20"
											icon={faSpinner}
											className="text-secondary"
										/>
									</div>
								)}
							</Card.Body>
						</Card>
					</div>
				) : null}
			</>
		)
	}
)
