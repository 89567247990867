import React, { forwardRef } from "react"
import { ListGroupItem } from "react-bootstrap"

interface Props {
	id: number | string
	data: GoogleApiYouTubeChannelResource
}

export const Item = forwardRef<string, Props>(({ data: { snippet } }, ref) => {
	return (
		<ListGroupItem
			// @ts-ignore
			ref={ref}
			className="align-middle bg-grey-30 text-white"
		>
			<img
				className="playlist-icon me-2"
				src={snippet.thumbnails.default.url}
				alt="logo"
			/>
			{snippet.title}
		</ListGroupItem>
	)
})
