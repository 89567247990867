import React from "react"
import { components } from "../generated/deckview.tv"

interface UserContextInterface {
	google_data: components["schemas"]["GoogleToken"] | null
	account_data: components["schemas"]["User"] | null
}

export const UserContext = React.createContext<UserContextInterface>({
	google_data: null,
	account_data: null,
})

export const SettingsContext = React.createContext<
	components["schemas"]["UserSettings"]
>({
	id: "",
	hide_watched: false,
	fullscreen: false,
	finish_percent: 95,
	start_percent: 3,
})
