const { REACT_APP__FRONTEND_HOST } = process.env

enum Method {
	GET = "GET",
	POST = "POST",
}

async function _doFetch<ReturnType>(
	url: string,
	method: Method,
	apiKey: string | null,
	body?: object | null,
	baseUrl?: string
): Promise<ReturnType> {
	const targetUrl =
		(baseUrl || "https://youtube.googleapis.com/youtube/v3") + url

	return fetch(targetUrl, {
		method: method,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${apiKey}`,
			Accept: "application/json",
		},
		body: body ? JSON.stringify(body) : null,
	}).then((resp) => resp.json())
}

export async function getSubscriptions(
	apiKey: string,
	page?: string
): Promise<GoogleApiYouTubePaginationInfo<GoogleApiYouTubeChannelResource>> {
	const params = new URLSearchParams()
	params.set("part", "snippet,contentDetails")
	params.set("mine", "true")
	params.set("order", "alphabetical")
	params.set("maxResults", "50")
	if (page) {
		params.set("pageToken", page)
	}
	const subData = await _doFetch<
		GoogleApiYouTubePaginationInfo<GoogleApiYouTubeSubscriptionResource>
	>(`/subscriptions?${params.toString()}`, Method.GET, apiKey, null)
	const ids = subData?.items.map((sub) => {
		return sub.snippet.resourceId.channelId
	})
	return await getChannels(apiKey, ids, subData.nextPageToken)
}

async function getChannels(
	apiKey: string,
	ids: string[],
	nextPageToken?: string
): Promise<GoogleApiYouTubePaginationInfo<GoogleApiYouTubeChannelResource>> {
	const params = new URLSearchParams()
	params.set("part", "snippet,contentDetails")

	for (const id of ids) {
		params.append("id", id)
	}

	params.set("maxResults", "50")

	const result = await _doFetch<
		GoogleApiYouTubePaginationInfo<GoogleApiYouTubeChannelResource>
	>(`/channels?${params.toString()}`, Method.GET, apiKey, null)
	if (nextPageToken != null) result.nextPageToken = nextPageToken
	return result
}

export function getVideos(
	apiKey: string,
	playlistId: string,
	page?: string
): Promise<
	GoogleApiYouTubePaginationInfo<GoogleApiYouTubePlaylistItemResource>
> {
	const params = new URLSearchParams()
	params.set("part", "snippet")
	params.set("playlistId", playlistId)
	params.set("maxResults", "50")
	params.set("pageToken", page || "")

	return _doFetch<
		GoogleApiYouTubePaginationInfo<GoogleApiYouTubePlaylistItemResource>
	>(`/playlistItems?${params.toString()}`, Method.GET, apiKey, null)
}

export function getVideoStats(
	apiKey: string,
	ids: string[]
): Promise<GoogleApiYouTubePaginationInfo<GoogleApiYouTubeVideoResource>> {
	const params = new URLSearchParams()
	params.set("part", "snippet,contentDetails,statistics")

	for (const id of ids) {
		params.append("id", id)
	}

	params.set("maxResults", "50")

	return _doFetch<
		GoogleApiYouTubePaginationInfo<GoogleApiYouTubeVideoResource>
	>(`/videos?${params.toString()}`, Method.GET, apiKey, null)
}
