import React, { useContext } from "react"

import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { components } from "../generated/deckview.tv"
import { SettingsContext, UserContext } from "../lib/context"
import { ChannelMap } from "../lib/interfaces"
import { SubBoxColumn } from "./sub-box-column"

interface SubBoxProps {
	id: string
	columns: components["schemas"]["UserColumn"][]
}

const SubBoxColumns = React.memo<SubBoxProps>(({ columns, id }) => {
	const { google_data } = useContext(UserContext)
	const { finish_percent, fullscreen, start_percent, hide_watched } =
		useContext(SettingsContext)

	return (
		<>
			{google_data && (
				<div id={id} className="d-flex">
					{columns.map((col) => {
						return (
							<SubBoxColumn
								key={col.channel_id}
								column={col}
								googleData={google_data}
								fullscreen={fullscreen}
								finishPercent={finish_percent}
								startPercent={start_percent}
								hideWatched={hide_watched}
							/>
						)
					})}
				</div>
			)}
		</>
	)
})

interface Props {
	ready: boolean
	subs: ChannelMap
	columns: components["schemas"]["UserColumn"][]
	columnsLoading: boolean
	hideWatched?: boolean
}

const SubBox: React.FC<Props> = ({
	columns,
	hideWatched,
	ready,
	columnsLoading,
}) => {
	const hideWatchedClass = hideWatched ? " hide-watched" : ""

	return (
		<div className={`d-flex fill-min-height${hideWatchedClass}`}>
			{columnsLoading && (
				<div
					id="sub-box"
					className="d-flex justify-content-center align-items-center"
				>
					<FontAwesomeIcon
						pulse
						transform="grow-80"
						className="text-secondary"
						icon={faSpinner}
					/>
				</div>
			)}
			{ready && <SubBoxColumns id="sub-box" columns={columns} />}
		</div>
	)
}
export default SubBox
