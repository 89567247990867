import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import React from "react"
import {
	Badge,
	Card,
	Col,
	OverlayTrigger,
	ProgressBar,
	Row,
	Tooltip,
} from "react-bootstrap"
import Moment from "react-moment"
import { components } from "../../generated/deckview.tv"
import { numberWithCommas, truncate } from "../../lib/util"
// add login button
interface Props {
	videoData: GoogleApiYouTubeVideoResource
	watchData: components["schemas"]["WatchData"]
	logo?: string
	showVideo: (id: string) => void
	googleData: components["schemas"]["GoogleToken"]
	toggleWatched: (id: string) => void
}

export const Video: React.FC<Props> = ({
	showVideo,
	toggleWatched,
	videoData: { contentDetails, id, snippet, statistics },
	watchData: { progress, progress_percent, video_id, watched },
}) => {
	const watchClass = watched ? " watched" : ""
	const eyeIcon = watched ? faEye : faEyeSlash
	let badgeString
	let badgeColour

	// @ts-ignore
	const liveBroadcastContent = snippet.liveBroadcastContent as
		| "live"
		| "none"
		| "upcoming"

	if (liveBroadcastContent === "upcoming") {
		badgeString = "UPCOMING"
		badgeColour = "secondary"
	} else if (liveBroadcastContent === "live") {
		badgeString = "LIVE"
		badgeColour = "danger"
	} else {
		badgeColour = "black"
		const duration = moment.duration(contentDetails.duration)
		badgeString =
			duration.asSeconds() > 60
				? //@ts-ignore
				  duration.format("h:mm:ss")
				: `0:${duration.asSeconds()}`
	}

	return (
		<Card className={`mb-1 bg-grey-30 video-card p-0 g-0${watchClass}`}>
			<Row className="p-0 g-0">
				<Col style={{ flexGrow: 0 }} className="p-0 g-0">
					<div role="button">
						<img
							onClick={(event) => showVideo(id)}
							alt="video"
							className="v-img"
							src={snippet.thumbnails.medium.url}
							loading="lazy"
						/>
						<div className="duration-badge">
							<Badge bg={badgeColour}>{badgeString}</Badge>
						</div>
						{progress ? (
							<ProgressBar
								variant="danger"
								now={progress_percent}
								className="rounded-0"
								style={{ height: 4 }}
							/>
						) : null}
					</div>
				</Col>
				<Col className="align-items-start p-0 g-0 d-inline-flex flex-column justify-content-between">
					<Row className="p-0 g-0 w-100">
						<Col xs={11} className="p-0 g-0 ps-1">
							<OverlayTrigger
								placement="bottom"
								delay={{ show: 1000, hide: 100 }}
								overlay={<Tooltip>{snippet.title}</Tooltip>}
							>
								<div role="button">
									<h6 className="v-title" onClick={() => showVideo(id)}>
										{truncate(snippet.title, 60)}
									</h6>
								</div>
							</OverlayTrigger>
						</Col>
						<Col xs={1} className="text-end p-0 g-0">
							<FontAwesomeIcon
								role="button"
								transform="shrink-28"
								className="text-dark fa-fw"
								// @ts-ignore
								icon={eyeIcon}
								onClick={() => toggleWatched(video_id)}
							/>
						</Col>
					</Row>
					<Row className="w-100 p-0 g-0">
						<Col xs={12} className="p-0 g-0">
							<div className="d-flex justify-content-between align-content-end flex-row mx-1">
								<span className="v-uploaded">
									{<Moment fromNow>{snippet.publishedAt}</Moment>}
								</span>
								<span className="v-views">
									{numberWithCommas(statistics.viewCount || 0)} views
								</span>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	)
}
