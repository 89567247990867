import { faGoogle } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useGoogleLogin } from "@react-oauth/google"
import React from "react"
import {
	Button,
	Container,
	Image,
	Nav,
	NavDropdown,
	Navbar,
} from "react-bootstrap"
import { Link } from "react-router-dom"
import { components } from "../generated/deckview.tv"
import { GoogleCode } from "../lib/interfaces"

interface UserProfileLinkProps {
	title?: string
	imageSrc?: string
}

const UserProfileLink: React.FC<UserProfileLinkProps> = ({
	imageSrc,
	title,
}) => (
	<div className="d-inline">
		{title}
		<Image
			className="profile-pic"
			src={imageSrc}
			loading="lazy"
			referrerPolicy="no-referrer"
		/>
	</div>
)

interface Props {
	hideWatched: boolean
	toggleHideWatched?: () => void
	showColumnManager?: () => void
	showSettingsManager?: () => void
	showCustomColumnManager?: () => void
	login: (code: GoogleCode | null) => void
	logout: () => void
	user: components["schemas"]["User"] | null
	permissions: components["schemas"]["UserPermissions"] | null
}

export const MainNav: React.FC<Props> = ({
	user,
	hideWatched,
	login,
	logout,
	permissions,
	showColumnManager,
	showCustomColumnManager,
	showSettingsManager,
	toggleHideWatched,
}) => {
	const watchedText = hideWatched ? "Show Watched" : "Hide Watched"

	const googleLogin = useGoogleLogin({
		onSuccess: login,
		flow: "auth-code",
		scope:
			"email openid profile https://www.googleapis.com/auth/youtube.readonly",
	})
	return (
		<Navbar bg="dark" fixed="top" expand="lg" variant="dark">
			<Container fluid>
				<Navbar.Brand href="/app">
					<img
						alt=""
						src="/deckview-tv-64.png"
						width="30"
						height="30"
						className="d-inline-block align-middle ml-1"
					/>{" "}
					deckview.tv
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="top-nav" />
				<Navbar.Collapse className="justify-content-end" id="top-nav">
					<Nav>
						{user?.id && (
							<NavDropdown
								title={
									<UserProfileLink title={user.name} imageSrc={user.picture} />
								}
								align={"end"}
							>
								{showSettingsManager && (
									<NavDropdown.Item onClick={showSettingsManager}>
										Settings
									</NavDropdown.Item>
								)}
								{showColumnManager && (
									<NavDropdown.Item onClick={showColumnManager}>
										Manage Columns
									</NavDropdown.Item>
								)}
								{showCustomColumnManager && permissions?.custom_columns && (
									<NavDropdown.Item onClick={showCustomColumnManager}>
										Manage Custom Columns
									</NavDropdown.Item>
								)}
								{toggleHideWatched && (
									<NavDropdown.Item onClick={toggleHideWatched}>
										{watchedText}
									</NavDropdown.Item>
								)}
								<NavDropdown.Divider />
								<NavDropdown.Item
									as={Link}
									to={"/privacy"}
									className="text-decoration-none text-black"
								>
									Privacy Policy
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									to={"/tos"}
									className="text-decoration-none text-black"
								>
									Terms of Service
								</NavDropdown.Item>
								<NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
							</NavDropdown>
						)}
						{!user?.id && (
							<>
								<Nav.Link as={Link} to={"/privacy"} className="text-light">
									Privacy Policy
								</Nav.Link>
								<Nav.Link as={Link} to={"/tos"} className="text-light">
									Terms of Service
								</Nav.Link>
								<Nav.Item className="google-font">
									<Button onClick={googleLogin}>
										<FontAwesomeIcon
											// @ts-ignore
											icon={faGoogle}
										/>{" "}
										Login with Google
									</Button>
								</Nav.Item>
							</>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
