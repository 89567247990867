import React, { useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { components } from "../generated/deckview.tv"

interface Props {
	userData: components["schemas"]["User"]
	settings: components["schemas"]["UserSettings"]
	handleClose: () => void
	handleSave: (data: components["schemas"]["UserSettings"]) => void
}

export const SettingsManager: React.FC<Props> = ({
	userData,
	handleClose,
	handleSave,
	settings,
}) => {
	const [hideWatched, setHideWatched] = useState(settings.hide_watched)
	const [startPercent, setStartPercent] = useState(settings.start_percent)
	const [finishedPercent, setFinishedPercent] = useState(
		settings.finish_percent
	)
	const [fullscreen, setFullscreen] = useState(settings.fullscreen)

	return (
		<>
			<Modal.Header className="bg-grey-20 text-white border-dark" closeButton>
				Manage Settings ,{" "}
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="formStartPercent">
						<Form.Label>Start Percent</Form.Label>
						<Form.Range
							value={startPercent}
							min="0"
							max="100"
							onChange={(e) => setStartPercent(parseInt(e.target.value))}
						/>
						<Form.Text className="text-muted">
							Progress will only start tracking when a video has been viewed{" "}
							{startPercent}% of duration.
						</Form.Text>
					</Form.Group>
					<Form.Group className="mb-3" controlId="formFinishedPercent">
						<Form.Label>Finished Percent</Form.Label>
						<Form.Range
							value={finishedPercent}
							min="0"
							max="100"
							onChange={(e) => setFinishedPercent(parseInt(e.target.value))}
						/>
						<Form.Text className="text-muted">
							Videos will be marked as watched when viewed {finishedPercent}% of
							duration.
						</Form.Text>
					</Form.Group>
					<Form.Group>
						<Form.Check
							type="switch"
							label="Show video player fullscreen"
							defaultChecked={fullscreen}
							onClick={() => setFullscreen(!fullscreen)}
						/>
						<Form.Text className="text-muted">
							{fullscreen
								? "When viewing a window the modal will be take over the entire window."
								: "Videos will played in a modal window."}
						</Form.Text>
					</Form.Group>
					<Form.Group>
						<Form.Check
							type="switch"
							label="Hide watched videos"
							defaultChecked={hideWatched}
							onClick={() => setHideWatched(!hideWatched)}
						/>
						<Form.Text className="text-muted">
							{hideWatched
								? "Videos that have been watched will be hidden. Empty columns will not appear."
								: "All videos will be visible, with watched videos being greyed out."}
						</Form.Text>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer className="bg-grey-20 p-1 border-dark">
				<Button variant="outline-dark text-light" onClick={handleClose}>
					Close
				</Button>
				<Button
					variant="secondary"
					onClick={() =>
						handleSave({
							id: userData.id,
							hide_watched: hideWatched,
							fullscreen: fullscreen,
							start_percent: startPercent,
							finish_percent: finishedPercent,
						})
					}
				>
					Save changes
				</Button>
			</Modal.Footer>
		</>
	)
}
