import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import YouTube from "react-youtube"
import { YouTubePlayer } from "youtube-player/dist/types"
import { components } from "../generated/deckview.tv"
import useWindowDimensions from "../lib/util"

interface Props {
	show: boolean
	handleHide: () => void
	modalFullscreen: boolean
	logo: string
	videoData: GoogleApiYouTubeVideoResource
	watchData: components["schemas"]["WatchData"]
	onStateChange: (e: { target: YouTubePlayer }) => void
}

export const VideoPlayer: React.FC<Props> = ({
	handleHide,
	logo,
	modalFullscreen,
	onStateChange,
	show,
	videoData: { id, snippet },
	watchData: { finished, progress },
}) => {
	const { height } = useWindowDimensions()
	const [fullscreen, setFullscreen] = useState<boolean>(modalFullscreen)

	const [showPlayer, setShowPlayer] = useState(false)

	const [playerElement, setPlayerElement] = useState<YouTubePlayer | null>(null)

	const handleExiting = async () => {
		if (playerElement) {
			await playerElement.pauseVideo()
		}
	}

	const [isHovering, setIsHovering] = useState(false)

	return (
		<Modal
			show={show}
			onHide={handleHide}
			onEntered={() => setShowPlayer(true)}
			onExiting={handleExiting}
			centered
			contentClassName="border-0"
			fullscreen={fullscreen || undefined}
			dialogClassName={!fullscreen ? "modal-xxl" : ""}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<Modal.Header
				className={`bg-dark text-light border-0 py-2 px-3${
					!isHovering && fullscreen ? " d-none" : ""
				}`}
				closeButton
			>
				<Modal.Title as={"h5"}>
					<img
						src={logo}
						alt="logo"
						loading="lazy"
						className="playlist-icon me-2"
						referrerPolicy="no-referrer"
						onClick={() => setFullscreen(!fullscreen)}
					/>
					{snippet.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-0 bg-black">
				{showPlayer && (
					<YouTube
						videoId={id || ""}
						onStateChange={(e) => setPlayerElement(e.target)}
						onPause={onStateChange}
						onPlay={onStateChange}
						onEnd={onStateChange}
						opts={{
							width: "100%",
							height: fullscreen
								? `${height - 57 + (!isHovering ? 50 : 0)}px`
								: `${height - 250}px`,

							playerVars: {
								modestbranding: 1,
								fs: 1,
								start: finished ? 0 : progress,
								autoplay: 1,
							},
						}}
					/>
				)}
			</Modal.Body>
		</Modal>
	)
}
