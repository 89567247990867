import moment from "moment"
import { useEffect, useState } from "react"

export function truncate(text: string, length: number): string {
	if (text.length / length > 1.1) {
		const temp = text.slice(0, length - 3)
		return `${temp}...`
	}
	return text
}

export function numberWithCommas(x: number) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function sortVideos(
	a: GoogleApiYouTubeVideoResource,
	b: GoogleApiYouTubeVideoResource
): number {
	return moment(a.snippet.publishedAt) < moment(b.snippet.publishedAt) ? 1 : 0
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height,
	}
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions())
		}

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	return windowDimensions
}
